import axios from "axios"
import React from "react"
import carrierlist from "./carrierlist.json"
import billToLocation from "./billToLocation.json"
import carrierInfo from "./carrierInfo.json"
import updateCarrierresponse from "./updateCarrier.json"

const BASE_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:10011"

export async function goFetch(route, options) {
    const { ...innerOptions } = options || {}
    try {
        const token = localStorage.getItem("token:carrierOnboarding")
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            ...options.headers,
        }
        const sentOptions = {
            ...innerOptions,
            headers,
            baseURL: BASE_URL,
        }
        const response = await axios(route, sentOptions)
        return response
    } catch (error) {
        throw error
    }
}

export async function requestCarrierList(cpgCode) {
    console.log("requestCarrierList")
    try {
        // const ltlSelectApiToken = localStorage.getItem(
        //     "token:ltlSelectApiToken"
        // )
        // const carrierInfo = await goFetch(
        //     `/carrierlist/${cpgCode}?ltlSelectApiToken=${ltlSelectApiToken}`,
        //     {
        //         method: "GET",
        //         credentials: "same-origin",
        //         headers: { "cache-control": "no-cache" },
        //     },
        //     true
        // )
        // return carrierInfo
        const response = { data: carrierlist } // Initialize response as an object
        console.log("carrierlist", response)
        return response
    } catch (error) {
        throw error
    }
}

export async function requestCarrierFields(carrierId) {
    try {
        // const carrierFields = await goFetch(
        //     `/carrierinfo/${carrierId}`,
        //     {
        //         method: "GET",
        //         credentials: "same-origin",
        //         headers: { "cache-control": "no-cache" },
        //     },
        //     true
        // )
        // return carrierFields
        const response = { data: carrierInfo } // Initialize response as an object
        console.log("carrierInfo", response)
        return response
    } catch (error) {
        throw error
    }
}

export async function requestCarrierData(cpgCode, carrierId) {
    try {
        const ltlSelectApiToken = localStorage.getItem(
            "token:ltlSelectApiToken"
        )
        const carrierData = await goFetch(
            `/carrier/${cpgCode}/${carrierId}?ltlSelectApiToken=${ltlSelectApiToken}`,
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        if (carrierData.data.id != null) return carrierData
        throw new Error("Not Found")
    } catch (error) {
        throw error
    }
}

export async function requestBillToLocation() {
    try {
        // const billToLocation = await goFetch(
        //     "/billToLocation",
        //     {
        //         method: "GET",
        //         credentials: "same-origin",
        //         headers: { "cache-control": "no-cache" },
        //     },
        //     true
        // )
        // return billToLocation
        const response = { data: billToLocation } // Initialize response as an object
        console.log("billToLocation", response)
        return response
    } catch (error) {
        throw error
    }
}

export async function addCarrier(cpgCode, carrierId, values) {
    const ltlSelectApiToken = localStorage.getItem("token:ltlSelectApiToken")
    // const payload = {
    //     code: carrierId,
    //     capacityProviderIdentifier: {
    //         type: "SCAC",
    //         value: carrierId,
    //     },
    //     accounts: [values],
    //     ltlSelectApiToken,
    // }
    try {
        // const addResult = await goFetch(
        //     `/carrier/${cpgCode}`,
        //     {
        //         method: "POST",
        //         credentials: "same-origin",
        //         headers: { "cache-control": "no-cache" },
        //         data: payload,
        //     },
        //     true
        // )
        // return addResult
        const response = { data: carrierlist } // Initialize response as an object
        console.log("carrierlist", response)
        return response
    } catch (error) {
        throw error
    }
}

export async function updateCarrier(carrierData, values) {
    const payload = {
        ...carrierData,
        accounts: [values],
    }
    try {
        // const updateResult = await goFetch(
        //     `/carrier/${carrierData.group.code}/${carrierData.id}`,
        //     {
        //         method: "PUT",
        //         credentials: "same-origin",
        //         headers: { "cache-control": "no-cache" },
        //         data: payload,
        //     },
        //     true
        // )
        // return updateResult
        const response = { data: updateCarrierresponse } // Initialize response as an object
        console.log("carrierlist", response)
        return response
    } catch (error) {
        throw error
    }
}

export async function deleteCarrier(carrierData) {
    try {
        const ltlSelectApiToken = localStorage.getItem(
            "token:ltlSelectApiToken"
        )
        const deleteResult = await goFetch(
            `/carrier/${carrierData.group.code}/${carrierData.id}`,
            {
                method: "DELETE",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: { ltlSelectApiToken, carrier: carrierData.code },
            },
            true
        )
        return deleteResult
    } catch (error) {
        throw error
    }
}
