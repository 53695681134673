/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core"
import { get } from "lodash"
import { Formik, Field, Form } from "formik"
import {
    requestCarrierFields,
    requestCarrierData,
    addCarrier,
    updateCarrier,
    deleteCarrier,
} from "../requests"
import GlobalSpinner from "../utility/global-spinner"
import FormikTextField from "../form/formikTextField"
import FormikSwitch from "../form/formikSwitch"
import Tutorial from "../tutorial"

const styles = () => ({
    submit__button: {
        marginTop: "20px",
    },
    field: {
        margin: "10px 0",
        width: "100%",
    },
    edit__carrier__container: {
        padding: "25px",
        marginTop: "85px",
    },
    tutorial__container: {
        paddingLeft: "30px",
    },
    formControl: {
        minWidth: 120,
    },
    fields__container: {
        width: "100%",
    },
    removed__text: {
        marginTop: "95px",
    },
})

class EditCarrier extends Component {
    state = {
        carrierFields: null,
        loading: false,
        carrierFieldsError: null,
        carrierData: null,
        p44Id: null,
        removed: false,
        dialogOpen: false,
    }

    componentDidMount = async () => {
        const { match, cpgCode } = this.props

        await this.setState({ loading: true })
        await Promise.all([
            this.getFieldInfo(match),
            match.params.mode === "edit" && this.getFieldData(match),
        ])
        this.setState({ loading: false })
        console.log("cpgCode", cpgCode)
    }

    onCancelClick = () => {
        if (this.props.match.params.mode === "add") {
            this.props.history.push(`/carrier/add`)
        }
    }

    getFieldInfo = async match => {
        try {
            const carrierFieldsResponse = await requestCarrierFields(
                match.params.carrierId
            )
            this.setState({ carrierFields: carrierFieldsResponse.data })
            if (match.params.mode === "add") {
                const initialValues = {}
                get(
                    carrierFieldsResponse,
                    "data.accountDefinitions[0].accountFieldDefinitions",
                    []
                ).forEach(item => {
                    if (item.code === "directionCode")
                        initialValues.directionCode = "SHIPPER"
                    else if (item.code === "paymentTermsCode")
                        initialValues.paymentTermsCode = "PREPAID"
                    else if (item.code === "accountFlag1")
                        initialValues.accountFlag1 = false
                    else initialValues[item.code] = ""
                })
                initialValues.enableDirectionOverride = true
                initialValues.enablePaymentTermsOverride = true
                this.setState({
                    carrierData: {
                        data: {
                            accounts: [initialValues],
                        },
                    },
                })
            }
        } catch (error) {
            this.setState({
                carrierFieldsError: get(error, "response.data", ""),
            })
        }
    }

    getFieldData = async match => {
        try {
            const carrierDataResponse = await requestCarrierData(
                this.cpgCode,
                match.params.carrierId
            )
            if (
                get(carrierDataResponse, "data") === "" ||
                !get(carrierDataResponse, "data")
            ) {
                this.setState({ removed: true })
            } else {
                this.setState({ carrierData: carrierDataResponse })
            }
        } catch (error) {
            this.setState({
                carrierFieldsError: get(error, "response.data", ""),
                removed: true,
            })
        }
    }

    deleteCarrier = async () => {
        this.handleDialogClose()
        try {
            await this.setState({ loading: true })
            await deleteCarrier(this.state.carrierData.data)
            this.setState({ removed: true, loading: false })
        } catch (error) {
            this.setState({ removed: false, loading: false })
            this.props.openSnackbar("error", "Error deleting carrier")
        }
    }

    handleDialogOpen = () => {
        this.setState({ dialogOpen: true })
    }

    handleDialogClose = () => {
        this.setState({ dialogOpen: false })
    }

    determineFieldComponent = code => {
        let fieldComponent
        switch (code) {
            case "accountFlag1":
                fieldComponent = FormikSwitch
                break
            default:
                fieldComponent = FormikTextField
        }
        return fieldComponent
    }

    render() {
        const {
            classes,
            match,
            history,
            openSnackbar,
            billToLocation,
            mode,
        } = this.props
        const {
            carrierFields,
            loading,
            carrierData,
            removed,
            dialogOpen,
        } = this.state
        const ignoredFields = [
            "enableDirectionOverride",
            "enablePaymentTermsOverride",
            "billToLocationId",
            "paymentTermsCode",
            "directionCode",
        ]
        console.log("billToLocation123", billToLocation)
        console.log("this.props", this.props)
        console.log("this.state", this.state)
        console.log("this.state", this.state)
        return loading ? (
            <GlobalSpinner />
        ) : removed ? (
            <Grid container justify="center" className={classes.removed__text}>
                <Typography variant="headline" color="primary">
                    <FormattedMessage
                        id="editCarrier.removalSuccess"
                        defaultMessage="You have successfully removed {name} from {location}"
                        values={{
                            name: get(
                                carrierFields,
                                "capacityProvider.name",
                                ""
                            ),
                            location: get(
                                billToLocation,
                                "data.contact.companyName",
                                <FormattedMessage
                                    id="editCarrier.removalSuccess.yourLocation"
                                    defaultMessage="your location"
                                />
                            ),
                        }}
                    />
                </Typography>
                <Grid item container justify="center">
                    <Button
                        onClick={() => window.close()}
                        variant="contained"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms.close"
                            defaultMessage="Close"
                        />
                    </Button>
                </Grid>
            </Grid>
        ) : (
            <Formik
                initialValues={get(carrierData, "data.accounts[0]", {})}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                    await this.setState({ loading: true })
                    if (mode === "edit") {
                        try {
                            const updateResponse = await updateCarrier(
                                carrierData.data,
                                values
                            )
                            this.setState({ carrierData: updateResponse })
                            openSnackbar(
                                "success",
                                <FormattedMessage
                                    id="editCarrier.succesfullyUpdated"
                                    defaultMessage="Successfully updated carrier credentials"
                                />
                            )
                        } catch (error) {
                            const validationMessages = get(
                                error,
                                "response.data.errorMessages",
                                []
                            )
                            const otherErrors = get(
                                error,
                                "response.data.error",
                                {
                                    message: (
                                        <FormattedMessage
                                            id="editCarrier.errorEditing"
                                            defaultMessage="Error editing carrier credentials"
                                        />
                                    ),
                                }
                            )

                            openSnackbar(
                                "error",
                                validationMessages.length > 0
                                    ? validationMessages[0]
                                    : otherErrors
                            )
                        }
                    } else if (mode === "add") {
                        try {
                            await addCarrier(
                                this.cpgCode,
                                match.params.carrierId,
                                {
                                    ...values,
                                    accountDefinitionIdentifier: get(
                                        carrierFields,
                                        "accountDefinitions[0].accountDefinitionIdentifier"
                                    ),
                                    billToLocationId: get(
                                        billToLocation,
                                        "data.id"
                                    ),
                                }
                            )
                            openSnackbar(
                                "success",
                                <FormattedMessage
                                    id="editCarrier.successfullyAddedCarrier"
                                    defaultMessage="Successfully added carrier"
                                />
                            )
                            history.push(`/carrier/add`)
                        } catch (error) {
                            const validationMessages = get(
                                error,
                                "response.data.errorMessages",
                                []
                            )
                            const otherErrors = get(
                                error,
                                "response.data.error",
                                {
                                    message: (
                                        <FormattedMessage
                                            id="editCarrier.errorAddingCarrier"
                                            defaultMessage="Error adding carrier"
                                        />
                                    ),
                                }
                            )

                            openSnackbar(
                                "error",
                                validationMessages.length > 0
                                    ? validationMessages[0]
                                    : otherErrors
                            )
                        }
                    }
                    this.setState({ loading: false })
                }}
            >
                {({ ...formikProps }) => (
                    <Grid
                        container
                        className={classes.edit__carrier__container}
                    >
                        <Grid item container xs={4} alignContent="flex-start">
                            <Grid item container justify="space-between">
                                <Grid item>
                                    <Typography variant="title">
                                        {get(
                                            carrierFields,
                                            "capacityProvider.name",
                                            ""
                                        )}
                                    </Typography>
                                </Grid>
                                {mode === "edit" && (
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                this.handleDialogOpen()
                                            }
                                        >
                                            <FormattedMessage
                                                id="editCarrier.removeCarrier"
                                                defaultMessage="Remove Carrier"
                                            />
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item container>
                                <Form className={classes.fields__container}>
                                    <Grid container direction="column">
                                        {get(
                                            carrierFields,
                                            "accountDefinitions[0].accountFieldDefinitions",
                                            []
                                        ).map((field, index) => {
                                            if (
                                                !ignoredFields.includes(
                                                    field.code
                                                )
                                            ) {
                                                return (
                                                    <Grid
                                                        item
                                                        container
                                                        xs={12}
                                                        key={index}
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            xs={
                                                                field.helpNeeded
                                                                    ? 11
                                                                    : 12
                                                            }
                                                            alignItems="center"
                                                        >
                                                            <Field
                                                                className={
                                                                    field.code !==
                                                                        "accountFlag1" &&
                                                                    classes.field
                                                                }
                                                                name={
                                                                    field.code
                                                                }
                                                                label={
                                                                    field.name
                                                                }
                                                                key={field.code}
                                                                type={
                                                                    (field.code ===
                                                                        "credential1" ||
                                                                        field.code ===
                                                                            "credential2" ||
                                                                        field.code ===
                                                                            "credential3") &&
                                                                    "password"
                                                                }
                                                                component={this.determineFieldComponent(
                                                                    field.code
                                                                )}
                                                                required={
                                                                    field.constraint ===
                                                                        "REQUIRED" &&
                                                                    field.code !==
                                                                        "accountFlag1"
                                                                }
                                                                classes={
                                                                    classes
                                                                }
                                                            />
                                                        </Grid>
                                                        {/* {field.helpNeeded && (
                                                            <Grid
                                                                item
                                                                container
                                                                alignItems="center"
                                                                justify="center"
                                                                xs={1}
                                                            >
                                                                <Tooltip
                                                                    title="Help"
                                                                    placement="left"
                                                                    enterDelay={
                                                                        500
                                                                    }
                                                                >
                                                                    <IconButton color="primary">
                                                                        <InfoIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        )} */}
                                                    </Grid>
                                                )
                                            }
                                            return undefined
                                        })}
                                        <Grid
                                            item
                                            container
                                            justify={
                                                mode === "edit"
                                                    ? "flex-end"
                                                    : "space-between"
                                            }
                                        >
                                            {mode === "add" && (
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={
                                                        formikProps.isSubmitting
                                                    }
                                                    className={
                                                        classes.submit__button
                                                    }
                                                    onClick={() =>
                                                        this.onCancelClick()
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="generalTerms.cancel"
                                                        defaultMessage="Cancel"
                                                    />
                                                </Button>
                                            )}
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    formikProps.isSubmitting
                                                }
                                                className={
                                                    classes.submit__button
                                                }
                                            >
                                                {mode === "add" ? (
                                                    <FormattedMessage
                                                        id="generalTerms.submit"
                                                        defaultMessage="Submit"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        id="generalTerms.update"
                                                        defaultMessage="Update"
                                                    />
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={8}
                            className={classes.tutorial__container}
                        >
                            <Tutorial code={get(match, "params.carrierId")} />
                        </Grid>
                        <Dialog
                            open={dialogOpen}
                            onClose={this.handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Confirm"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <FormattedMessage
                                        id="editCarrier.deleteConfirmation"
                                        defaultMessage="Are you sure you want to delete this carrier account? This cannot be undone."
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleDialogClose}
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="generalTerms.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button
                                    onClick={() => this.deleteCarrier()}
                                    color="primary"
                                    variant="contained"
                                    autoFocus
                                >
                                    <FormattedMessage
                                        id="generalTerms.delete"
                                        defaultMessage="Delete"
                                    />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                )}
            </Formik>
        )
    }
}

EditCarrier.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    billToLocation: PropTypes.object.isRequired,
    openSnackbar: PropTypes.func.isRequired,
}

export default withStyles(styles)(EditCarrier)
