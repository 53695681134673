import { React, useState, useEffect, Fragment } from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { requestBillToLocation } from "./requests"
import Header from "./header"
import AddCarrier from "./addCarrier/index"
import EditCarrier from "./editCarrier/index"
import { initUserRecord, loggedIn } from "../../actions/authorization"
import { Route, withRouter, Redirect } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const useStyles = makeStyles(theme => ({
    decision__container: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        color: "white",
        paddingTop: "20px",
        marginTop: "-64px",
    },
}))

export const CarrierOnboard = props => {
    const { mode, trackGA, cpgCode } = props
    const [billToLocation, setBillToLocation] = useState(null) // State for billToLocation
    const [readyToLoad, setReadyToLoad] = useState(false) // Loading state
    const classes = useStyles()
    const { carrierId } = useParams()

    useEffect(() => {
        if (!loggedIn()) {
            // console.log(`I was running`)
            // history.push("/")
            // window.location.reload()
            // return
            // return <Redirect to={{ pathname: "/user/signup" }} />
            window.location.href = "/"
        }
        const fetchBillToLocation = async () => {
            try {
                const location = await requestBillToLocation()
                setBillToLocation(location)
            } catch (error) {
                console.error("Error fetching billToLocation:", error)
                setBillToLocation(null) // Set to null in case of an error
            } finally {
                setReadyToLoad(true)
            }
        }
        fetchBillToLocation()
    }, [])
    return (
        <Fragment>
            <Header {...props} billToLocation={billToLocation} mode={mode} />
            {mode == "add" && !carrierId ? (
                <AddCarrier
                    {...props}
                    cpgCode={cpgCode}
                    mode={mode}
                    billToLocation={billToLocation}
                /> // Pass cpgCode
            ) : (
                <EditCarrier
                    {...props}
                    cpgCode={cpgCode}
                    mode={mode}
                    billToLocation={billToLocation}
                /> // for EditCarrier
            )}
        </Fragment>
    )
}
