/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable arrow-parens */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import AddCarrierTable from "./addCarrierTable"
import { requestCarrierList } from "../requests"
import GlobalSpinner from "../utility/global-spinner"

class AddCarrier extends Component {
    state = {
        carrierList: null,
        carrierListLoading: false,
        carrierListError: null,
    }

    componentDidMount = async () => {
        this.setState({ carrierListLoading: true })
        const { match, cpgCode } = this.props // Access cpgCode from props
        console.log("Props", this.props)
        try {
            const carrierListResponse = await requestCarrierList(cpgCode)
            this.setState({ carrierList: carrierListResponse.data })
        } catch (error) {
            this.setState({ carrierListError: get(error, "response.data", "") })
        }
        this.setState({ carrierListLoading: false })
    }

    onCarrierSelect = carrier => {
        const { history, match, cpgCode } = this.props
        history.push(
            `/carrier/add/${carrier?.capacityProviderIdentifiers?.[0]?.value}`
        )
    }

    render() {
        const {
            carrierList,
            carrierListLoading /* carrierListError */,
        } = this.state
        console.log("You are here with carrier list ", carrierList)
        console.log("You are here with carrierListLoading ", carrierListLoading)
        return carrierListLoading ? (
            <GlobalSpinner />
        ) : (
            <Grid container>
                {carrierList && (
                    <AddCarrierTable
                        onCarrierSelect={this.onCarrierSelect}
                        carrierList={carrierList}
                    />
                )}
            </Grid>
        )
    }
}

AddCarrier.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

export default AddCarrier
