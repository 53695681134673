import React, { Component, useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactGA from "react-ga4"
import { get } from "lodash"
import Divider from "@material-ui/core/Divider"
import { Field, reduxForm, change, getFormValues } from "redux-form"
import createSelector from "re-reselect"
import { connect } from "react-redux"
import { composeValidators, combineValidators } from "revalidate"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Snackbar from "@material-ui/core/Snackbar"
import { Grid, Typography, Button } from "@material-ui/core"
import FormRadio from "../form/form-radio"
import { formValidation } from "../locations/validation"
import GlobalSpinner from "../common/GlobalSpinner.js"
import {
    signupSubmit,
    requestAccessToLocation,
    trackGAEvent,
    syncCarriersUserLocation,
    updateUserOnboardingDNA,
    manageLocationCarrier,
    retrieveUserSingUp,
    authenticated,
} from "../../actions/user"
import { logOut } from "../../actions/authorization"
import {
    countryPhoneValidator,
    acceptValidator,
    emailValidator,
    isRequired,
    isNumeric,
} from "../../actions/validation"
import RequestAccess from "../locations/request-access"
import {
    locationAddSuccess,
    requestAccessSuccess,
} from "../../messages/confirmation-constants"
import {
    requestAccessError,
    userSignUpError,
} from "../../messages/error-constants"
import PickUpInformation from "./pick-up-information.js"
import ShippingInformation from "./shipping-information.js"
import BillingInformation from "./billing-information.js"
import GeneralInformation from "./general-information.js"
import { FormattedMessage, injectIntl } from "react-intl"
import { supportedLanguagesCountryMap } from "../../misc"
import {
    shipperLocationTypes,
    thirdPartyLocationTypes,
} from "./../locations/addLocation.js"
import FormCheckbox from "../form/form-checkbox"
import { IntercomAPI } from "react-intercom"
import { useFlags } from "launchdarkly-react-client-sdk"
import { makeStyles } from "@material-ui/core/styles"
import RedirectP44Dialog from "../locations/components/RedirectP44Dialog.js"
import GlobalSpinnerWithMessage from "../common/GlobalSpinnerWithMessage.js"
import { useSnackbarContext } from "../../context/providers/snackbarProvider.js"

const termsLabel = language => (
    <div>
        <span>
            <FormattedMessage
                id="signUp__iAcceptThe"
                defaultMessage="I accept the "
            />
        </span>
        <span
            style={{ color: "blue" }}
            onClick={() => {
                window.open(`/#/terms/${language}`, "_blank")
            }}
        >
            <FormattedMessage
                id="signUp__termsAndConditions"
                defaultMessage="Terms And Conditions"
            />
        </span>
    </div>
)

const useStyles = makeStyles(theme => ({
    form__container: {
        padding: theme.spacing(3),
    },
    login__information: {
        marginBottom: "40px",
    },
    signup__form: {
        padding: "15px",
        marginTop: "-64px",
        height: "100%",
        alignContent: "flex-start",
    },
    signup__button: {
        marginLeft: "10px",
    },
    decision__container: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        color: "white",
        padding: "20px",
    },
    input__label: {
        color: "white !important",
    },
    cards__container: {
        paddingTop: "20px",
        width: "100%",
    },
    signup__decision__text: {
        paddingRight: "20px",
    },
    signup__section: {
        padding: "1%",
    },
    signup__card: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
        minHeight: "330px",
    },
    signup__card__variableSize: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    signup__card__top: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    terms__container: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "10px 20px",
    },
    acceptTerms__text: {
        paddingLeft: "40px",
    },
    checkbox: {
        color: "white",
    },
    whiteOverride: {
        color: "white",
    },
    error__entry: {
        padding: "15px",
    },
    bottom__push: {
        flexGrow: 1,
    },
    bottom__push__container: {
        height: "calc(100% - 20px)",
    },
    decision__divider: {
        height: "20px",
        borderTop: "solid 1px white",
        width: "100%",
    },
    decision__titleContainer: {
        padding: "20px",
    },
    decision__fedexContainer: {
        padding: "20px",
    },
    decision__fedexText: {
        color: "white",
    },
    field__root: {
        "background-color": theme.palette.primary.light,
    },
    input__field: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        minHeight: "20px",
        fontFamily: theme.typography.fontFamily,
    },

    error__title: {
        backgroundColor: theme.palette.primary.main,
    },
}))

const SignUpForm = props => {
    const {
        handleSubmit,
        submitting,
        pristine,
        invalid,
        onLogout,
        isInvited,
        accountType,
        trackGA,
        intl,
        preferences,
        changeField,
        locations,
        requestAccessToLocation,
        onSignUpSubmit,
        locationType,
        formValues = {},
        reset,
        onCarrierManagementClick,
        onRetrieveUserSingUp,
        onAuthenticated,
    } = props

    const classes = useStyles()

    const [requestAccessOpen, setRequestAccessOpen] = useState(false)
    const [duplicateLocation, setDuplicateLocation] = useState(null)
    const [locationCreated, setLocationCreated] = useState(null)
    const { openSnackbar } = useSnackbarContext()

    const [
        otherCarriersSelectedModal,
        setOtherCarriersSelectedModal,
    ] = useState(false)

    const [open, setOpen] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [loading, setLoading] = useState(false)

    const {
        enableOtherCarrierSignUp,
        enableMulticarrierSignupOnLtlselect,
    } = useFlags()

    const isRequested = get(locations, "[0].users[0].access.requested.date")

    const isShipper = accountType === "isShipper"
    const isThirdParty = accountType === "isThirdParty"
    const other = accountType === "other"

    const FormLabelProps = {
        classes: {
            root: classes.input__label,
            label: classes.input__label,
        },
    }

    const radioClasses = {
        root: classes.whiteOverride,
        colorSecondary: classes.whiteOverride,
        checked: classes.whiteOverride,
        label: classes.whiteOverride,
    }

    const syncCarrier = cpg => {
        try {
            const intervalFunction = setInterval(async () => {
                try {
                    const result = await syncCarriersUserLocation(cpg)
                    if (result && result.some(item => item !== 0)) {
                        clearInterval(intervalFunction)
                        await onAuthenticated()
                        window.location.reload(true)
                        return
                    }
                } catch {
                    console.warn(
                        "unable to sync and auth.... retrying carriers list"
                    )
                }
            }, 12000)
        } catch (error) {
            console.warn("unable to sync.... retrying carriers list", error)
        }
    }

    useEffect(() => {
        ReactGA.event("Sign Up", "Sign Up Page Viewed")
    }, [])

    useEffect(() => {
        ;(async () => {
            let user = await onRetrieveUserSingUp()
            const locationinProgress = user?.locations.find(
                location => location?.signUpInProgress
            )
            if (locationinProgress && user?.locations?.length == 1) {
                syncCarrier(locationinProgress?.cpgCode)
                setLocationCreated(locationinProgress)
                if (other) {
                    setOtherCarriersSelectedModal(true)
                }
            } else if (
                user?.locations?.length == 1 &&
                user?.locations[0].signUpInOtherCarrier
            ) {
                window.location.assign("/#home")
            }
        })()
    }, [])

    const handleRequestAccessClose = () => {
        setRequestAccessOpen(false)
        setDuplicateLocation(null)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleRequestAccess = async () => {
        try {
            await requestAccessToLocation(duplicateLocation)
            openSnackbar("success", requestAccessSuccess)
        } catch (error) {
            openSnackbar("error", requestAccessError)
        } finally {
            handleRequestAccessClose()
        }
    }

    const handleSignup = async values => {
        let locationResult
        setLoading(true)
        try {
            const { duplicateLocation, location } = await onSignUpSubmit(values)
            if (duplicateLocation) {
                setDuplicateLocation(duplicateLocation)
                setRequestAccessOpen(!!duplicateLocation)
            } else {
                if (!location?.signUpInProgress) {
                    openSnackbar("success", locationAddSuccess)
                }
            }
            if (location) {
                locationResult = location
                setLocationCreated(locationResult)
            }
        } catch (error) {
            setModalContent(userSignUpError(values?.firstname))
            IntercomAPI("trackEvent", "account-validation-error-sign-up")
            handleClickOpen()
            throw error
        } finally {
            if (locationResult?.signUpInProgress) {
                setOtherCarriersSelectedModal(true)
                syncCarrier(locationResult?.cpgCode)
            }
            setLoading(false)
        }
    }

    const handleAccountTypeSelect = () => {
        const { firstname, lastname, phone_number, language } = formValues

        reset()
        changeField("signup", "firstname", firstname)
        changeField("signup", "lastname", lastname)
        changeField(
            "signup",
            "phone_number.phone_number",
            phone_number?.phone_number
        )
        changeField("signup", "phone_number.extension", phone_number?.extension)
        changeField("signup", "phone_number.mobile", phone_number?.mobile)
        changeField("signup", "language", language)
        changeField("signup", "location.locationType", locationType)
    }

    const determineRequestedInfo = () => {
        let accountNumbers = ""

        if (!get(locations, "length")) return undefined
        const { is3rdPartyEnabled, isShipperEnabled } = locations[0]

        if (is3rdPartyEnabled && !isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__thirdPartyAccount"
                    defaultMessage="third party account number {number}"
                    values={{
                        number: locations[0].fedexBillToAccount,
                    }}
                />
            )
        } else if (!is3rdPartyEnabled && isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__shipperAccount"
                    defaultMessage="shipper account number {number}"
                    values={{
                        number: locations[0].fedexFreightAccount,
                    }}
                />
            )
        } else if (is3rdPartyEnabled && isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__shipperAndThirdPartyAccount"
                    defaultMessage="shipper account number {shipperNumber} and third party account number {thirdPartyNumber}"
                    values={{
                        shipperNumber: locations[0].fedexFreightAccount,
                        thirdPartyNumber: locations[0].fedexBillToAccount,
                    }}
                />
            )
        } else return undefined

        return (
            <FormattedMessage
                id="locations.requestAccess__alreadyRequested"
                defaultMessage="You have already requested access to the location associated with {accountNumbers}, and your access is pending. Please reach out to the location owner to be granted access to this location or sign up with a different account number."
                values={{ accountNumbers }}
            />
        )
    }

    const requestedInfo = determineRequestedInfo()

    const handleClickOpenP44 = async () => {
        if (formValues?.formDNA) {
            updateUserOnboardingDNA()
        }
        // TODO CREATE LOCATION
        try {
            const onboardingUrl = await onCarrierManagementClick(
                locationCreated,
                null,
                null,
                formValues?.preferences?.language
            )
            window.open(onboardingUrl, "_blank")
            trackGA(
                "Signup",
                "Successful open of carrier onboarding portal and created location"
            )
        } catch {
            openSnackbar(
                "success",
                <FormattedMessage
                    id="location.carrierOnboardingPortalOpenError"
                    defaultMessage="Unable to open carrier onboarding portal"
                />
            )
            trackGA("Signup", "Unable to open carrier onboarding portal")
        } finally {
            setOtherCarriersSelectedModal(false)
        }
    }

    return (
        <Grid item container className={classes.signup__form}>
            {loading && <GlobalSpinner />}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    className={classes.error__title}
                    id="alert-dialog-title"
                >
                    <img
                        alt="LTL Select"
                        src="static/LTLselect-logo-type-new.png"
                    />
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid item container>
                        <Typography
                            variant="subtitle1"
                            className={classes.error__entry}
                        >
                            {modalContent}
                        </Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        color="primary"
                        autoFocus
                    >
                        <FormattedMessage
                            id="generalTerms__close"
                            defaultMessage="Close"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            {!isInvited && (
                <RequestAccess
                    open={requestAccessOpen}
                    data={duplicateLocation}
                    handleClose={() => handleRequestAccessClose()}
                    handleRequestAccess={() => handleRequestAccess()}
                />
            )}
            <Grid item container className={classes.decision__container}>
                <Grid item container alignItems="center" justify="center">
                    <Grid
                        item
                        container
                        justify="center"
                        className={classes.decision__titleContainer}
                    >
                        <img
                            alt="LTL Select"
                            src="static/LTLselect-logo-type-new.png"
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justify="center"
                        className={classes.decision__fedexContainer}
                    >
                        <a
                            href="https://www.fedexfreight.fedex.com/openacctemail.jsp"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                IntercomAPI("trackEvent", "sign-up-here-click")
                                trackGA(
                                    "Signup",
                                    "Needs FedEx Account - Text Click"
                                )
                            }}
                        >
                            <Grid item container>
                                <Typography
                                    variant="caption"
                                    className={classes.decision__fedexText}
                                >
                                    <FormattedMessage
                                        id="signUp__accountPrompt1"
                                        defaultMessage="Don't have a FedEx Freight account?"
                                    />
                                </Typography>{" "}
                                &nbsp;
                                <Typography variant="caption" color="secondary">
                                    <FormattedMessage
                                        id="signUp__accountPrompt2"
                                        defaultMessage="Sign up here."
                                    />
                                </Typography>
                            </Grid>
                        </a>
                    </Grid>
                    <Grid
                        item
                        justify="center"
                        className={classes.decision__divider}
                    />
                    {!isInvited && (
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Grid
                                item
                                container
                                xs={4}
                                justify="center"
                                alignItems="center"
                                className={classes.signup__decision__text}
                            >
                                <Typography variant="h6" color="inherit">
                                    <FormattedMessage
                                        id="signUp__title"
                                        defaultMessage="I want to sign up with: "
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6} container>
                                <Field
                                    onClick={() => handleAccountTypeSelect()}
                                    formlabelprops={FormLabelProps}
                                    component={FormRadio}
                                    radioClasses={radioClasses}
                                    formControlLabelClasses={radioClasses}
                                    id="addLocation__accountType"
                                    name="accountType"
                                    options={[
                                        {
                                            label: (
                                                <FormattedMessage
                                                    id="signUp__fedExShipperAccount"
                                                    defaultMessage="FedEx Shipper Account"
                                                />
                                            ),
                                            value: "isShipper",
                                        },
                                        {
                                            label: (
                                                <FormattedMessage
                                                    id="signUp__fedExThirdPartyAccount"
                                                    defaultMessage="FedEx Third Party Account"
                                                />
                                            ),
                                            value: "isThirdParty",
                                        },
                                        {
                                            label: (
                                                <FormattedMessage
                                                    id="signUp__other"
                                                    defaultMessage="Other Carrier"
                                                />
                                            ),
                                            value: "other",
                                        },
                                    ].filter(
                                        option =>
                                            (!enableOtherCarrierSignUp &&
                                                option?.value !== "other") ||
                                            enableOtherCarrierSignUp
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <form
                onSubmit={handleSubmit(values => handleSignup(values))}
                className={classes.cards__container}
            >
                <Grid item container>
                    <Grid item xs={6} className={classes.signup__section}>
                        <Grid item container className={classes.signup__card}>
                            <GeneralInformation
                                country={
                                    supportedLanguagesCountryMap[
                                        preferences?.language
                                    ] || "US"
                                }
                            />
                        </Grid>
                    </Grid>
                    {(isShipper || other) && !isInvited && (
                        <Grid item xs={6} className={classes.signup__section}>
                            <Grid
                                item
                                container
                                className={classes.signup__card}
                            >
                                <ShippingInformation
                                    locationTypes={shipperLocationTypes}
                                    changeField={changeField}
                                    formName={"signup"}
                                    otherCarrier={other}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!isInvited && (isShipper || other) && (
                        <Grid item xs={6} className={classes.signup__section}>
                            <Grid
                                item
                                container
                                className={classes.signup__card}
                            >
                                <PickUpInformation
                                    country={
                                        formValues?.location?.shippingAddress
                                            ?.address?.country
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!isInvited && (isShipper || isThirdParty || other) && (
                        <Grid item xs={6} className={classes.signup__section}>
                            <Grid
                                item
                                container
                                className={classes.signup__card__variableSize}
                            >
                                <BillingInformation
                                    isShipper={isShipper}
                                    isThirdParty={isThirdParty}
                                    locationTypes={thirdPartyLocationTypes}
                                    otherCarrier={other}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {isRequested && (
                    <Grid item container justify="center">
                        <Typography variant="subtitle1">
                            {" "}
                            {requestedInfo}{" "}
                        </Typography>
                    </Grid>
                )}
                {(isShipper || isThirdParty || isInvited || other) && (
                    <Grid item className={classes.signup__section}>
                        <Grid
                            item
                            container
                            className={classes.terms__container}
                            alignItems="center"
                        >
                            <Grid
                                item
                                xs={8}
                                className={classes.acceptTerms__text}
                            >
                                <Field
                                    component={FormCheckbox}
                                    label={termsLabel(intl?.locale)}
                                    name="termsVersion"
                                    id="termsVersion"
                                />
                            </Grid>
                            <Grid item container xs={4} justify="flex-end">
                                <Button
                                    id="cancel__button"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => onLogout()}
                                >
                                    <FormattedMessage
                                        id="generalTerms__cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={pristine || invalid || submitting}
                                    className={classes.signup__button}
                                    id="signUp__button"
                                    onClick={() => {
                                        if (
                                            other &&
                                            enableMulticarrierSignupOnLtlselect
                                        ) {
                                            window.open(
                                                "/#/carrier/add",
                                                "_blank"
                                            )
                                        }
                                    }}
                                >
                                    {other ? (
                                        <FormattedMessage
                                            id="signUp__signUp__select__carrier"
                                            defaultMessage="Select Carrier"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="signUp__signUp"
                                            defaultMessage="Sign Up"
                                        />
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {!other && !isShipper && !isThirdParty && !isInvited && (
                    <Grid item container direction="column">
                        <Grid item className={classes.bottom__push} />
                        <Grid
                            item
                            container
                            className={classes.terms__container}
                            justify="flex-end"
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => onLogout()}
                            >
                                <FormattedMessage
                                    id="generalTerms__cancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </form>
            <RedirectP44Dialog
                dialogOpen={otherCarriersSelectedModal}
                handleClose={() => {
                    setOtherCarriersSelectedModal(false)
                    window.location.reload(true)
                }}
                onContinue={() => {
                    handleClickOpenP44(location)
                    trackGA("Signup", "Redirect Notice Modal - Sign Up Click")
                }}
            />
        </Grid>
    )
}

const userSelector = createSelector(
    state => state.user.profile,
    getFormValues("signup"),
    (state, props) => props,
    (user, form, props) => {
        let { locations, ...innerUser } = user || {}
        locations = get(user, "locations", [])
        const isInvited = locations.some(item =>
            get(item, "users[0].access.invited")
        )

        const locationinProgress =
            locations.length == 1 &&
            locations.find(location => location?.signUpInProgress)

        const defaultLanguage = props?.intl?.locale
        const defaultCountry =
            supportedLanguagesCountryMap[defaultLanguage] || "US"

        return {
            isInvited,
            initialValues: {
                ...innerUser,
                notificationType: "none",
                preferences: {
                    language: defaultLanguage ? defaultLanguage : "en-us",
                },
                location: isInvited
                    ? undefined
                    : {
                          readyTime: "6:00 AM",
                          closeTime: "1:00 PM",
                          address: {
                              country: defaultCountry,
                          },
                          shippingAddress: {
                              address: {
                                  country: defaultCountry,
                              },
                          },
                          sameAsShippingAddress: false,
                          ...(locationinProgress ?? {}),
                      },
            },
            accountType: form?.accountType,
            locationType: form?.location?.locationType,
            formValues: form,
            locations,
        }
    }
)(state => get(state, "user.profile._id", ""))

const mapStateToProps = userSelector

const mapDispatchToProps = dispatch => ({
    onSignUpSubmit: values => dispatch(signupSubmit(values)),
    onLogout: () => {
        dispatch(trackGAEvent("Signup", "Cancel Button Click"))
        dispatch(logOut())
    },
    requestAccessToLocation: location =>
        dispatch(requestAccessToLocation(location)),
    changeField: (form, field, value) => dispatch(change(form, field, value)),
    trackGA: (category, action) => dispatch(trackGAEvent(category, action)),
    onCarrierManagementClick: (location, carrierCode, accountId, language) =>
        dispatch(
            manageLocationCarrier(location, carrierCode, accountId, language)
        ),
    onRetrieveUserSingUp: values => dispatch(retrieveUserSingUp(values)),
    onAuthenticated: values => dispatch(authenticated()),
})

const userValidation = combineValidators({
    firstname: isRequired({
        field: {
            id: "userPreferences.generalInformation__firstName",
            defaultMessage: "First Name",
        },
    }),
    lastname: isRequired({
        field: {
            id: "userPreferences.generalInformation__lastName",
            defaultMessage: "Last Name",
        },
    }),
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "userPreferences.generalInformation__email",
                defaultMessage: "Email Address",
            },
        }),
    },
    phone_number: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(undefined, "preferences.language")
        )({
            field: {
                id: "userPreferences.generalInformation__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "userPreferences.generalInformation__extension",
                defaultMessage: "Extension",
            },
        }),
        mobile: countryPhoneValidator(
            undefined,
            "preferences.language"
        )({
            field: {
                id: "userPreferences.generalInformation__mobileNumber",
                defaultMessage: "Mobile Number",
            },
        }),
    },
    termsVersion: acceptValidator({
        field: {
            id: "signUp__termsAndConditions",
            defaultMessage: "Terms and Conditions",
        },
    }),
    "preferences.language": isRequired({
        field: {
            id: "userPreferences.generalInformation__language",
            defaultMessage: "Language",
        },
    }),
    location: formValidation,
})

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "signup", // a unique identifier for this form
            validate: userValidation,
        })(SignUpForm)
    )
)
